<template>
  <v-container class="pa-0">
    <task-option-modal
      v-if="showTaskOptionModal"
      option="plan"
      :mode="planOption"
      :default-number="defaultWaitTime"
      @close="closeOptionModal"
      @action="doOptionAction"
    />
    <v-row
      class="header"
      justify="space-around"
    >
      <img
        src="@/assets/back.png"
        alt="뒤로가기"
        type="button"
        @click="$router.replace('/task/work')"
      >
      <span class="span-back"> &nbsp;&nbsp;뒤로 </span>
      <v-spacer />
      <template v-if="workData === null">
        <span
          class="span-task-add"
          type="button"
          @click="addPlan"
        >
          &nbsp;&nbsp;생성&nbsp;
        </span>
      </template>
      <template v-else>
        <span
          class="span-task-add"
          type="button"
          @click="addPlan"
        >
          &nbsp;&nbsp;저장&nbsp;
        </span>
      </template>
    </v-row>
    <v-row class="content-container mt-10">
      <v-col>
        <v-col cols="12">
          <input
            v-model="planName"
            type="text"
            class="input-task-name"
            placeholder="태스크명 입력"
          >
        </v-col>
        <v-col
          v-for="(item, i) in planList"
          :key="i"
          class="btn-container mt-4"
          cols="12"
        >
          <v-card
            class="task"
            color="#d4d4d4"
          >
            <v-row
              justify="space-between"
            >
              <v-col cols="4">
                <v-select
                  v-model="item.location_name"
                  class="priority"
                  :items="locationNames"
                  item-color="grey"
                  outlined
                  dark
                  dense
                />
              </v-col>
              <v-spacer />
              <v-col cols="7">
                <v-row>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#7c7c7c"
                      dark
                      @click="setPlanSetting(i)"
                    >
                      설정
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#00BFB4"
                      dark
                      @click="deletePlanAction(i)"
                    >
                      삭제
                    </v-btn>
                  </v-col>
                  <v-col cols="2" v-if="i>0">
                    <v-btn
                      height="30px"
                      width="40px"
                      min-width="20"
                      color="#B45594"
                      dark
                      @click="upPlan(i)"
                    >
                      ↑
                    </v-btn>
                  </v-col>
                  <v-col cols="2"  v-if="i<planList.length-1">
                    <v-btn
                      height="30px"
                      width="40px"
                      min-width="20"
                      color="#B49455"
                      dark
                      @click="downPlan(i)"
                    >
                      ↓
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          v-if="isShowAdd"
          class="btn-container mt-4"
          cols="12"
        >
          <v-card
            class="task"
            color="#d4d4d4"
          >
            <v-row
              justify="space-between"
            >
              <v-col cols="4">
                <v-select
                  v-model="planLocation"
                  class="priority"
                  :items="locationNames"
                  item-color="grey"
                  placeholder="위치 선택"
                  outlined
                  dark
                  dense
                />
              </v-col>
              <v-spacer />
              <v-col cols="7">
                <v-row>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#7c7c7c"
                      dark
                      @click="showTaskOptionModal = true"
                    >
                      설정
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#00BFB4"
                      dark
                      @click="addPlanAction"
                    >
                      저장
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#cc7c7c"
                      dark
                      @click="isShowAdd= false"
                    >
                      취소
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col class="btn-container mt-4">
          <v-row
            justify="center"
          >
            <v-btn
              v-if="!isShowAdd"
              class="btn-task"
              height="70px"
              margin="20px"
              color="#00BFB4"
              dark
              @click="isShowAdd= true"
            >
              추가
            </v-btn>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TaskOptionModal from '@/components/modals/task/taskOptionModal.vue';
import EventBus from '@/main.js';

export default {
  name: 'Plan',
  components: {
    TaskOptionModal
  },
  props: {
    locations: {
      type: Array,
      default: null
    },
    mapInfo: {
      type: Object,
      default: null
    },
    workData: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      showModal: false,
      showTaskOptionModal: false,
      locationNames: [],

      workId: null,

      planList: [],
      planName: '',
      planLocation: null,
      // planDefaultSelected: null,
      // 시간 체류, 지시 대기 등 옵션
      planOption: "MOVE",
      planIndex: null,

      modalConfigSelected: null,
      modalConfigNumber: null,

      selectedForAdd: null,
      numberForAdd: null,

      defaultWaitTime: null,
      isShowAdd: false,
    }
  },
  created() {
    // lifecycle을 고려한 EvenBus 초기화
    EventBus.$off('taskConfig');
    EventBus.$on('taskConfig', (configs) => {
      this.modalConfigSelected = configs.selected;
      this.modalConfigNumber = configs.inputNumber;
    });

    this.initialize();
  },
  methods: {
    initialize() {
      this.locations.forEach(location => {
        this.locationNames.push(location.location_name);
      });

      if (this.workData !== null) {
        this.initializeEditing();
      }
    },
    initializeEditing() {
      this.planName = this.workData[0].work_name;
      this.planList = this.workData[0].Tasks;
      this.planList.forEach(task => {
        this.locations.forEach(location => {
          if (location.location_id === task.location_id) {
            task.location_name = location.location_name;
          }
        });
      });
    },
    addPlan() {
      if (this.planName && this.planList.length) {
        this.planList.forEach((task, i) => {
          task.task_order = i;
          this.locations.forEach(location => {
            if (location.location_name === task.location_name) {
              task.location_id = location.location_id;
            }
          });
          delete task.location_name;
        });

        if (this.workData === null) {
          return this.postWork()
            .catch(err => { throw Error(err); });
        } else {
          return this.updateWork()
            .catch(err => { throw Error(err); });
        }

      } else if (!this.planName){
        this.$toast('태스크 명을 입력해 주세요');
      } else if (!this.planList.length) {
        this.$toast('최소 1개의 액션을 추가해 주세요');
      }
    },
    postWork() {
      return this.$axios.post('/work', {
        map_id: this.mapInfo.map_id,
        work_name: this.planName,
        Tasks: this.planList
      })
        .then(res => {
          if (res.status === 200) {
            this.$router.replace('/task/work');
          }
        });
    },
    updateWork() {
      return this.$axios.put(`/work/${this.workData[0].work_id}`, {
        work_id: this.workData[0].work_id,
        map_id: this.mapInfo.map_id,
        work_name: this.planName,
        Tasks: this.planList
      })
        .then(res => {
          if (res.status === 200) {
            this.$router.replace('/task/work');
          }
        });
    },
    addPlanAction() {
      if (this.planLocation) {
        const task = {};
        this.planOption = "MOVE";

        if(this.selectedForAdd === "GPIO IN"){
          this.planOption = "IN";
        }
        if(this.selectedForAdd === "GPIO OUT"){
          this.planOption = "OUT";
        }

        if (this.numberForAdd !== null) {
          task.wait_timeout = this.numberForAdd;
        } else {
          task.wait_timeout = 0;
        }
        task.location_name = this.planLocation;
        task.task_type = this.planOption;

        this.planList.push(task);

        // const elem = document.getElementsByClassName('content-container');
        // elem.scrollTop = elem.scrollHeight - elem.clientHeight;

        this.selectedForAdd = null;
        this.numberForAdd = null;
        this.planLocation = null;
        this.planOption = "MOVE";
        this.isShowAdd = false;
      } else {
        this.$toast('위치를 선택해 주세요');
      }
    },
    deletePlanAction(index) {
      this.planList.splice(index, 1);
    },
    closeOptionModal() {
      this.showTaskOptionModal = false;
      this.modalConfigSelected = null;
      this.modalConfigNumber = null;
      this.defaultWaitTime = null;
      this.planIndex = null;
      this.planOption = "MOVE";
    },
    doOptionAction() {
      if (this.modalConfigSelected === '시간 체류') {
        if(this.modalConfigNumber === null){
          this.$toast('경유옵션과 체류시간를 모두 입력해 주세요', {
            position: 'top-center'
          });
        }else if (this.modalConfigNumber < 0) {
          this.$toast('0 이상의 수를 입력해 주세요', {
            position: 'top-center'
          });
        } else {
          if (typeof this.planIndex === 'number') {
            this.planList[this.planIndex].wait_timeout = this.modalConfigNumber;
            this.planList[this.planIndex].task_type = 'MOVE';
          } else {
            this.selectedForAdd = this.modalConfigSelected;
            this.numberForAdd = this.modalConfigNumber;
          }
          this.closeOptionModal();
        }
      }

      if(this.modalConfigSelected === 'GPIO IN'){
        if (typeof this.planIndex === 'number') {
          this.planList[this.planIndex].wait_timeout = this.modalConfigNumber;
          this.planList[this.planIndex].task_type = 'IN';
        } else {
          this.selectedForAdd = this.modalConfigSelected;
          this.numberForAdd = this.modalConfigNumber;
        }
        this.closeOptionModal();
      }

      if(this.modalConfigSelected === 'GPIO OUT'){
        if (typeof this.planIndex === 'number') {
          this.planList[this.planIndex].wait_timeout = this.modalConfigNumber;
          this.planList[this.planIndex].task_type = 'OUT';
        } else {
          this.selectedForAdd = this.modalConfigSelected;
          this.numberForAdd = this.modalConfigNumber;
        }
        this.closeOptionModal();
      }
    },
    setPlanSetting(index) {
      this.planIndex = index;
      this.planOption = this.planList[index].task_type;
      this.defaultWaitTime = Number(this.planList[index].wait_timeout);
      this.showTaskOptionModal = true;
    },
    upPlan(index){
      let planList = [...this.planList];
      let temp = {...planList[index]};
      planList[index] = {...planList[index-1]};
      planList[index-1] = temp;
      this.planList = planList;
    },
    downPlan(index){
      let planList = [...this.planList];
      let temp = {...planList[index]};
      planList[index] = {...planList[index+1]};
      planList[index+1] = temp;
      this.planList = planList;
    },
  }
}
</script>

<style lang="scss" scoped>
.span-back {
  color: #ffffff;
  font-size: 80px !important;
}

.span-task-add  {
  justify-self: right;
  color: #ffffff;
  font-size: 80px !important;
}

.input-task-name {
  width: 60%;
  height: 8vh;
  margin: 1vh 0px 0px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3vw;
  color: white;
}

.content-container {
  height: 80vh;
  overflow: auto;
}

.task {
  border-radius: 15px !important;
  vertical-align: middle;
  font-size: 50px;
  color: #7c7c7c !important;
}

.btn-task {
  width: 80%;
  border-radius: 10px;
  font-size: 40px !important;
  font-weight: bold !important;
}

.priority {
  margin: 3px 0 0 20px;
  background-color: black;
  border: 2px solid #545861;
}

@media (max-width: 1700px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .span-back {
    font-size: 40px !important;
  }

  .span-task-add {
    font-size: 40px !important;
  }

  .task {
    height: 50px;
    font-size: 30px;
  }

  .task > .row > .col {
    padding: 0 !important;
  }

  .btn-task {
    height: 30px !important;
    font-size: 13px !important;
  }
}

@media (max-width: 1140px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .span-back {
    font-size: 20px !important;
  }

  .span-task-add {
    font-size: 20px !important;
  }
}
</style>

<style>
.left-right-container > .right-area > .container > .content-container > .col > .btn-container > .task > .row > .col > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}

.left-right-container > .right-area > .container > .content-container > .col > .btn-container > .task > .row > .col > .v-input > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
